






















































































































































































































































import SelectWarehouse from "@/components/custom/select/SelectWarehouse.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { APagination, useAsset } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import {
  CustomListCategory,
  DataListAssetBook,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { assetsServices } from "@/services/assets.service";
import { masterServices } from "@/services/master.service";
import { ColumnDef } from "@/types";
import printJs from "print-js";
import Vue from "vue";
import { mapGetters } from "vuex";

type Record = AssetResponseDto & {
  loading: boolean;
};

export default Vue.extend({
  name: "AssetInquiry",
  mixins: [MNotificationVue],
  components: {
    SelectWarehouse,
  },
  data() {
    this.getListAssetCategories = debounceProcess(this.getListAssetCategories);
    this.getListAssetStatus = debounceProcess(this.getListAssetStatus);
    return {
      DEFAULT_PAGE,
      PAGE_SIZE_OPTIONS,
      isModalShow: false as boolean,
      unitCode: [] as string[],
      selectedRowKeys: [] as string[],
      dataListCategory: [] as CustomListCategory[],
      dataListStatus: [] as ResponseListMaster[],
      pagination: {
        totalElements: 0,
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
      },
      dataListBook: [] as DataListAssetBook[],
      loadingLocation: false as boolean,
      loadingListAssetCategory: false as boolean,
      loadingFind: false as boolean,
      loadingStatus: false as boolean,
      dataSource: [] as Record[],
      columnsTable: [
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNo",
          width: "120px",
          fixed: "left",
        },
        {
          title: this.$t("lbl_category"),
          key: "category",
          width: "250px",
          customRender: (_: string, record: Record) => {
            return record.assetCategory.id
              .split(".")
              .map(str => {
                return str.replace(/\b[a-z](?=[a-z]{2})/g, e =>
                  e.toUpperCase()
                );
              })
              .join(".");
          },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: "75px",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: "170px",
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: "150px",
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: "250px",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_warehouse"),
          key: "warehouse",
          width: "150px",
          customRender: (_: string, record: AssetResponseDto) => {
            return `${record.assetLocation.warehouse.branchWarehouse.name}, ${record.assetLocation.warehouse.name}`;
          },
        },
        {
          title: this.$t("lbl_quantity"),
          dataIndex: "unit",
          width: "100px",
          key: "unit",
        },
        {
          title: this.$t("lbl_life_months"),
          dataIndex: "currentLifeMonths",
          key: "currentLiftMonths",
          width: "120px",
        },
        {
          title: this.$t("lbl_acquisition_date"),
          key: "acquisitionDate",
          width: "150px",
          customRender: (text: string) => {
            return dateFormat(text);
          },
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "currentCostAsset",
          key: "currentCostAsset",
          width: "150px",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          width: "250px",
          key: "customerName",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_customer_location"),
          dataIndex: "customerLocation",
          key: "customerLocation",
          width: "250px",
          customRender: (text: string) => (text && text.trim()) || "-",
        },
      ] as ColumnDef[],
      form: this.$form.createForm(this, { name: "inquirySearch" }),
      formRules: {
        category: {
          label: "lbl_category",
          name: "category",
          decorator: ["category"],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          decorator: ["assetNumber"],
        },
        status: {
          label: "lbl_status",
          name: "status",
          decorator: ["status"],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          decorator: ["serialNumber"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          decorator: ["unitCode"],
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          decorator: ["warehouse"],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      hasPrivilege: "authStore/hasPrivilege",
    }),
  },
  created() {
    this.getListAssetCategories("");
    this.getListAssetStatus();
    this.allowToggleActive();
    this.showActionColumns();
    this.getAssetInquiryList();
  },
  methods: {
    showActionColumns() {
      if (
        !this.hasPrivilege("asset-adjustment:update") &&
        !this.hasPrivilege("asset-retirement:create")
      )
        return;

      this.columnsTable.push({
        title: this.$t("lbl_action").toString(),
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        fixed: "right",
        align: "center",
      } as ColumnDef);
    },
    disallowToggle(status: string) {
      const allowStatus: string[] = ["READY", "NEED_TO_REPAIR", "INACTIVE"];
      return !allowStatus.includes(trimSpaceToUnderscore(status));
    },
    onChangeActive(record: Record) {
      const { toggleAssetActive } = useAsset();
      const text =
        record.status === AssetStateEnum.INACTIVE
          ? this.$t("confirmation.toggle-ready")
          : this.$t("confirmation.toggle-inactive");
      this.showConfirmation(text, async () => {
        try {
          record.loading = true;
          await toggleAssetActive(record.id);

          const params = new RequestQueryParams();
          params.page = this.pagination.page - 1;
          params.search = this.dynamicSearch(this.form.getFieldsValue());
          this.getAssetInquiryList(params);
        } catch {
          this.showNotifError("notif_process_fail");
        } finally {
          record.loading = false;
        }
      });
    },
    allowToggleActive() {
      if (!this.hasPrivilege("asset-adjustment:update")) return;

      this.columnsTable.push({
        title: this.$t("lbl_active").toString(),
        key: "active",
        scopedSlots: { customRender: "active" },
        width: "90px",
        fixed: "right",
        align: "center",
      } as ColumnDef);
    },
    getListAssetStatus() {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_STATUS`,
      } as RequestQueryParamsModel;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    handleCancel() {
      this.form.resetFields();
      this.dataSource = [];
      this.selectedRowKeys = [];
    },
    handlePrintQr() {
      this.isModalShow = false;
      const params: RequestQueryParamsModel = {
        unitCodes: this.unitCode.join(","),
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        }
      });
    },
    handleViewQr() {
      const tempUnitCode: string[] = [];

      for (const data of this.dataSource) {
        if (this.selectedRowKeys.includes(data.id)) {
          tempUnitCode.push(data.unitCode);
        }
      }

      this.unitCode = tempUnitCode;
      this.isModalShow = true;
    },
    onSelectChange(value: string[]) {
      this.selectedRowKeys = value;
    },
    assignSearch(key, value, and): string {
      if (key === "category" && value)
        return and + `assetCategory.secureId~*${value}*`;
      else if (key === "assetNumber" && value) return and + `assetNo~${value}`;
      else if (key === "status" && value) return and + `status~${value}`;
      else if (key === "serialNumber" && value)
        return and + `serialNumber~*${value}*`;
      else if (key === "unitCode" && value) return and + `unitCode~*${value}*`;
      else if (key === "warehouse" && value)
        return and + `assetLocation.warehouse.secureId~${value.key}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    async getAssetInquiryList(params?: RequestQueryParamsModel): Promise<void> {
      try {
        this.loadingFind = true;
        const response = await assetsServices.getListMasterAsset(params);
        this.dataSource = response.data.map<Record>(data => ({
          ...data,
          loading: false,
        }));
        this.pagination.totalElements = response.totalElements;
      } catch {
        this.dataSource = [];
        this.pagination.totalElements = 0;
      } finally {
        this.loadingFind = false;
        this.selectedRowKeys = [];
      }
    },
    handleFind() {
      const params = new RequestQueryParams();
      this.pagination.page = 1;
      params.limit = this.pagination.limit;
      params.search = this.dynamicSearch(this.form.getFieldsValue());

      this.getAssetInquiryList(params);
    },
    getListAssetCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `categoryId~*${valueSearch}*_OR_description~*${valueSearch}*`;
      this.loadingListAssetCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              first: "",
              second: "",
              idForView: "",
            } as CustomListCategory;

            let idCategories = data.category.id.split(".");

            idCategories = idCategories.map(dataMap => {
              let temp = dataMap.split("");
              if (temp.length > 0) {
                temp[0] = temp[0].toUpperCase();
                dataMap = temp.join("");
              }
              return dataMap;
            });

            tempSegment.idForView = idCategories.join(".");
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
        })
        .finally(() => (this.loadingListAssetCategory = false));
    },
    onChangeTable({ current, pageSize }: APagination): void {
      this.pagination.page =
        pageSize !== this.pagination.limit ? DEFAULT_PAGE : current;
      this.pagination.limit = pageSize;

      const params = new RequestQueryParams();
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      params.search = this.dynamicSearch(this.form.getFieldsValue());
      this.getAssetInquiryList(params);
    },
    allowRetirement(status: AssetStateEnum) {
      return status !== AssetStateEnum.INACTIVE;
    },
  },
});
